<ng-container *ngIf="showNavbar">
  <section>
    <header>
      <div class="logo logo-slider">
        <svg-icon src="/assets/images/logo_1.svg"></svg-icon>
        <svg-icon src="/assets/images/logo_2.svg"></svg-icon>
      </div>

      <div class="menu-buttons">
        <ul>
          <li>
            <a
              routerLink="/dashboard"
              routerLinkActive="active"
            >
              <svg-icon src="assets/images/icons/dashboard.svg"></svg-icon>
              <span>Dashboard</span>
            </a>
          </li>
          <li>
            <a data-cy="ordersHeaderButton"
              routerLink="/orders"
              routerLinkActive="active"
            >
              <svg-icon src="assets/images/icons/orders.svg"></svg-icon>
              <span>Orders</span>
            </a>
          </li>
        </ul>
      </div>

      <section class="navbar-buttons">
        <a class="btn nav-btn green" data-cy="newQuoteHeaderButton" routerLink="/orders/new">
          Get a new quote
        </a>
        <a class="btn nav-btn horizontal" *ngIf="isLogged" (click)="logout()" data-cy="logoutHeaderButton">
          Logout <svg-icon src="assets/images/icons/log-out-2.svg"></svg-icon>
        </a>
        <a class="btn nav-btn horizontal" *ngIf="!isLogged" routerLink="/login" data-cy="loginHeaderButton">
          Sign in
        </a>
      </section>

      <svg-icon
        *ngIf="!showMobileMenu && isMobileVieWidth"
        src="assets/images/icons/menu.svg"
        class="mobile-nav-btn"
        (click)="showMobileMenu = true"
      ></svg-icon>
      <svg-icon
        *ngIf="showMobileMenu && isMobileVieWidth"
        src="assets/images/icons/cross.svg"
        class="mobile-nav-btn"
        (click)="showMobileMenu = false"
      ></svg-icon>
    </header>
    <mobile-menu
      (closeMobileMenu)="closeMobileMenuNabvar()"
      *ngIf="showMobileMenu"
      [isLogged]="isLogged"
      (logout)="logout()"></mobile-menu>
  </section>
</ng-container>

<ng-container *ngIf="!showNavbar && isMobileVieWidth">
  <header>
    <div class="logo logo-slider">
      <svg-icon src="/assets/images/logo_1.svg"></svg-icon>
      <svg-icon src="/assets/images/logo_2.svg"></svg-icon>
    </div>
  </header>
</ng-container>
<div class="modal-backdrop" *ngIf="showBackDrop"></div>
